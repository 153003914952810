<template>
  <div v-if="characters && characters.length" class="container">
    <div class="row row-cols row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 no-space">
      <div
        v-for="(char, index) of characters"
        :key="char.id"
        class="col set-col"
        :class="
          isMobSmallScreen && index >= 6
            ? 'hide'
            : index >= 8 && isMobMiddleScreen
            ? 'hide'
            : index >= 10 && !isMobSmallScreen && !isMobMiddleScreen
            ? 'hide'
            : ''
        "
      >
        <div class="one-character mt-3">
          <div class="h-100">
            <div class="d-flex justify-content-center">
              <router-link
                v-if="char.username"
                :to="{ name: 'profile', params: { username: char.username } }"
                class="clickable-item-hov"
              >
                <img
                  v-image
                  class="char-img clickable-item-hov"
                  :src="resizeUpload(char.profile_picture_url, '190h')"
                />
              </router-link>
              <router-link
                v-else
                :to="{ name: 'character-profile-new', params: { slug: char.slug } }"
                class="clickable-item-hov"
                ><img
                  v-image
                  class="char-img clickable-item-hov"
                  :src="resizeUpload(get(char, 'info.cropProfilePicture'), '190h')"
              /></router-link>
            </div>

            <div class="name d-flex justify-content-center mt-2 text-center">
              <div v-if="char.username" class="d-flex">
                <router-link
                  :class="{
                    'text-color-username': usernameColor(char) && !usernameColor(char).startsWith('#'),
                    'bold-username': usernameColor(char),
                  }"
                  :style="
                    !usernameColor(char)
                      ? ''
                      : usernameColor(char).startsWith('#')
                      ? `color: ${usernameColor(char)} !important`
                      : `background-image: ${usernameColor(char)} !important`
                  "
                  :to="{ name: 'profile', params: { username: char.username } }"
                  class="clickable-item-hov"
                >
                  <span>
                    {{ char.username }}
                  </span>
                </router-link>
                <div
                  v-if="loggedInUser.id !== char.id && status?.role === 'leader' && privacy === 'private'"
                  class="clickable-item"
                  @click="remove(char.id, char.username)"
                >
                  <ion-icon :icon="trashOutline" class="mx-1" />
                </div>
              </div>

              <router-link
                v-else
                :to="{ name: 'character-profile-new', params: { slug: char.slug } }"
                class="clickable-item-hov"
                :class="isChatroom ? 'text-white' : 'text-black'"
                >{{ get(char, 'info.name') }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <ion-button
        v-if="worldstatus?.status === 'member' && route.name === 'social-space-details'"
        color="primary"
        class="see-all-btn ml-2"
        @click="addCharacter"
      >
        <div class="d-flex">
          <div class="text">Showcase Character</div>
        </div>
      </ion-button>
      <ion-button v-if="!isChatroom && hasMore" class="see-all-btn" color="primary" @click="seeallchar"
        >See all Characters</ion-button
      >
      <ion-button v-else-if="isChatroom" class="see-all-btn text-black" style="--background: #aaa" @click="seeallmember"
        >See all Members</ion-button
      >
    </div>

    <see-all-characters-modal :is-open="isOpen" :characters="characters" :is-member="isMember" @close="closeModal" />
  </div>
  <div v-else class="d-flex justify-content-center text-black">
    <div v-if="worldstatus?.status === 'member' && route.name === 'social-space-details'">
      <span class="mr-1 clickable-item" style="font-size: 16px; color: var(--ion-color-primary)" @click="addCharacter"
        >CLICK HERE
      </span>
      to showcase your character
    </div>
  </div>
  <add-character-in-world
    :is-open="isCharacterFormOpen"
    :member-status="worldstatus"
    :world-id="id"
    :privacy="privacy"
    @added="addedChar"
    @dismiss-modal="closeCharacterForm"
  ></add-character-in-world>
</template>

<script lang="ts" setup>
import { trashOutline } from 'ionicons/icons';
import { alertController } from '@ionic/vue';
import SeeAllCharactersModal from '@/shared/modals/SeeAllCharactersModal.vue';
import AddCharacterInWorld from '@/shared/modals/AddCharacterInWorld.vue';
import { removSocialSpaceChatroomMember } from '@/shared/actions/socialSpaceChatroom';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const isOpen = ref(false);

const props = defineProps({
  characters: {
    type: Object,
  },
  isChatroom: {
    type: Boolean,
    default: false,
  },
  status: {
    type: Object,
  },
  worldstatus: {
    type: Object,
  },
  privacy: {
    type: String,
  },
  id: {
    type: String,
  },
});

const characters = toRef(props, 'characters');
const isMember = ref(false);
const emits = defineEmits(['delete', 'added']);
const route = useRoute();
const isCharacterFormOpen = ref(false);
const { width: windowWidth } = useWindowSize();
const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 768;
});

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const isMobMiddleScreen = computed(() => {
  return windowWidth.value <= 991;
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const addedChar = () => {
  emits('added');
};

const closeCharacterForm = () => {
  isCharacterFormOpen.value = false;
};

const addCharacter = async () => {
  isCharacterFormOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};
onMounted(async () => {
  window.addEventListener('resize', handleResize);
});

const remove = async (id: any, name: string) => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to remove <b>${name}</b> from this chatroom.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await removSocialSpaceChatroomMember(route.params.id as string, id);
      emits('delete');
      toast.show('Member removed successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
  //
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const hasMore = computed(() => {
  return !!(
    (isMobSmallScreen.value && characters.value!.length >= 6) ||
    (characters.value!.length >= 8 && isMobMiddleScreen) ||
    characters.value!.length >= 10
  );
});
const { user } = authStore();
const loggedInUser = computed(() => {
  return user.value;
});

const seeallchar = () => {
  isOpen.value = true;
  isMember.value = false;
};

const seeallmember = () => {
  isOpen.value = true;
  isMember.value = true;
};
</script>

<style lang="sass" scoped>
.set-col
  @media(max-width:481px)
    max-width: calc(50% - 60px) !important
  @media(max-width: 400px)
    max-width: calc(50% - 4px) !important
  @media(max-width: 270px)
    max-width: 100% !important
.hide
  display: none
.no-space
  margin-left: 0
  margin-right: 0
  margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0
.see-all-btn
  --border-radius: 10px
  height: 30px
.row
  margin-top: 10px !important

.one-character
  img
    min-width: 90px
    width: 90px !important
    height: 90px !important
    border-radius: 45px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7

.characters-list
  overflow-x: auto
  background-color: #737373
.characters-list::-webkit-scrollbar
  display: none
.characters-list
  -ms-overflow-style: none
  scrollbar-width: none

.character-card
  overflow: unset !important
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
  .reaction-count
    font-size: 18px
    font-weight: bold
  @media(min-width: 420px)
    min-width: 28% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 50% !important
    max-width: 50% !important

.actions
  position: absolute
  bottom: 0

.see-all-text
  color: #214163
</style>
