<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100 px-4" style="overflow: auto">
        <form @submit.prevent="onSubmit">
          <div class="mb-2">
            <strong>Select the character you want to add in this world</strong>
          </div>
          <div class="mb-3">
            <ion-select
              v-model="addCharacter.selected_character"
              mode="md"
              placeholder="Select desired character"
              :multiple="false"
            >
              <ion-select-option v-for="char in userCharacters" :key="char.id" :value="char.id">{{
                get(char, 'info.name')
              }}</ion-select-option>
            </ion-select>
          </div>

          <div class="w-100 d-flex justify-content-center">
            <ion-button class="submit-btn" :disabled="isSubmitting || !isFormComplete" type="submit">
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { getUserCharacters } from '@/shared/actions/characters';
import { toast } from '@/shared/native';
import { createWorldCharUserRequest } from '@/shared/actions/worlds';
import { authStore } from '@/shared/pinia-store/auth';
import get from 'lodash/get';

const props = defineProps({
  modalTitle: { type: String, default: 'Add A Character' },
  isOpen: { type: Boolean, default: false },
  worldId: { type: String, required: true },
  memberStatus: { type: Object, required: true },
  privacy: { type: String, required: true },
});

const emit = defineEmits(['dismissModal', 'added']);

const modalTitle = toRef(props, 'modalTitle');
const isOpen = toRef(props, 'isOpen');
const worldId = toRef(props, 'worldId');
const memberStatus = toRef(props, 'memberStatus');
const privacy = toRef(props, 'privacy');

const areCharsLoading = ref(false);
const isSubmitting = ref(false);

const addCharacter = ref({
  message: '',
  selected_character: '',
});

const userCharacters = ref<any[]>([]);

const isFormComplete = computed(() => !!addCharacter.value.selected_character);

function dismissModal() {
  emit('dismissModal');
  isSubmitting.value = false;
}

async function onSubmit() {
  if (!isFormComplete.value) return;

  isSubmitting.value = true;

  try {
    await createWorldCharUserRequest({
      requestee_id: addCharacter.value.selected_character,
      requestee_type: 'character',
      world: worldId.value,
      message: addCharacter.value.message,
    });

    dismissModal();

    if (get(memberStatus.value, 'role') === 'leader') {
      await toast.show('Character added successfully', 'nonative', 'success');
    } else if (privacy.value === 'O') {
      await toast.show('Character added successfully', 'nonative', 'success');
      emit('added');
    } else {
      await toast.show('Request sent successfully', 'nonative', 'success');
    }
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    dismissModal();
  }

  isSubmitting.value = false;
}

async function fetchUserCharacters() {
  const { user } = authStore();
  areCharsLoading.value = true;
  userCharacters.value = await getUserCharacters(user.value.id, null);
  areCharsLoading.value = false;
}

onMounted(() => {
  const { isAuthenticated } = authStore();
  if (isAuthenticated.value) {
    fetchUserCharacters();
  }
});
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  @media(max-width: 599px) and (max-height: 767px)
    --height: 85%
    --width: 85%
</style>
